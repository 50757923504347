import React from "react"
import Layout from "../components/layout"
import Headline from "../components/headline"
import GridTwo from "../components/grid-2"
import { StyleSheet, css } from "aphrodite"

const servicesList = [
  {
    name: "Demand & Supply Planning",
    desc: "Sourcing, inventory & compliance",
    left: `
    <div class='has-text-weight-light is-size-4 has-text-primary'>
    We balance resources as per our client's requirements and establish and communicate plans for the entire supply chain. We provide a multitude of services to our clients in accordance to their requirement.
    </div>
    `,
    right: `
    <div class='has-text-weight-light'>
    <strong class='has-text-weight-light is-size-4'>Our services include:</strong><br/><br/>
    <ul style='list-style:disc'>
    <li style='margin-bottom:1rem'>Balancing resources with requirements and establishing & communicating plans for the whole supply chain.</li>
    <li style='margin-bottom:1rem'>Management of business rules, supply chain performance, data collection, inventory and capital.</li>
    <li style='margin-bottom:1rem'>Managing assets, transportation, planning configuration, regulatory requirements, compliance and supply chain.</li>
    <li style='margin-bottom:1rem'>Aligning the supply chain unit plan with the financial plan.</li>
    </ul>
    </div>
    `,
  },
  {
    name: "Sourcing",
    desc: "Receiving, verifying and transfer of products",
    left: `
    <div class='has-text-weight-light is-size-4 has-text-primary'>
    We schedule deliveries of products that comprises of receiving, verifying and transfer of products to authorized suppliers along with identification and selection of supply sources, when not predetermined by the client.
    </div>
    `,
    right: `
    <div class='has-text-weight-light'>
    At AWI, we manage business rules, assess suppliers’ performance, maintain data, capital assets, product incoming, suppliers’ network, import/export requirements, supplier agreements and supply chain.<br/><br/>Some major companies that are being catered for these services are Johnson & Johnson, Hindustan Unilever, Snofi-Aventis, Pfizer Corporation and Amway Corporation.
    </div>
    `,
  },
  {
    name: "Manufacturing",
    desc: "Own and contract manufacturing",
    imp1:
      "AWI is organized into own and contract manufacturing for leading multinationals, including all intermediate process required for production.",
    imp2:
      "AWI has also been venturing into the Pharmaceuticals industry and in order to deliver reliable products in specific timelines, our in house operations are highly skilled using advanced technologies. AWI has a wide range of Pharmacy products ranging from Antiseptic Formulations, Herbal Cosmetics to Containers and Closures, which are either sold through personal networks or outsourced to business partners.",
    left: `
    <div class='has-text-weight-light'>
    Personal Production<br/><br/>
    <ul style='list-style:disc'>
    We manufacture products for some of the top most companies across various domains. We take care of made-to-stock and made-to-order production execution. The process of manufacturing includes:<br/><br/>

    <li style='margin-bottom:1rem'>Securing licenses and approvals for products and processes.</li>
    <li style='margin-bottom:1rem'>Schedule production activities, issue product, produce & test, package, stage product and release product to delivery.</li>
    <li style='margin-bottom:1rem'>Manage rules, performance, data, in-process products (WIP), equipment facilities and transportation.</li>
    <li style='margin-bottom:1rem'>Production network, regulatory compliance for production and supply chain.</li>
    </ul>
    </div>
    `,
    right: `
    <div class='has-text-weight-light'>
    Contract Manufacturing<br/><br/>
    <ul style='list-style:disc'>
    The company has acquired statutory approvals from the following authorities:<br/><br/>

    <li style='margin-bottom:1rem'>Bureau of Indian Standards</li>
    <li style='margin-bottom:1rem'>Environmental Clearance from Central Government</li>
    <li style='margin-bottom:1rem'>DIC Registration</li>
    </ul>
    </div>
    `,
  },
  {
    name: "Marketing & Sales",
    desc: "Marketing & sales strategies across businesses",
    left: `
    <div class='has-text-weight-light is-size-4 has-text-primary'>
    AWI is backed with a team of charged marketing and sales personnel to analyze the market trend and come up with competitive strategies to outdo its competitors. Our strength lies in our people and in innovative solutions that specifically addresses the emerging market segment of India.
    </div>
    `,
    right: `
    <div class='has-text-weight-light'>
    Some of the key services we offer in this field are:<br/><br/>
    <ul style='list-style:disc'>
    <li style='margin-bottom:1rem'>Identify the unmet needs, opportunities in the fast growing markets and assess the demand scenario.</li>
    <li style='margin-bottom:1rem'>Reach out and tap the identified customer segments.</li>
    <li style='margin-bottom:1rem'>Design services and products to deliver plans.</li>
    <li style='margin-bottom:1rem'>Setting systems to collate customer and market feedback to continuously upgrade products & services.</li>
    <li style='margin-bottom:1rem'>Track and work for changes in business environment that might impact the existing potential demand.</li>
    <li style='margin-bottom:1rem'>Focus on institutional customers and consumers for sale.</li>
    </ul>
    </div>
    `,
  },
  {
    name: "Delivery",
    desc: "Warehousing, inquiries & shipments",
    left: `
    <div class='has-text-weight-light is-size-4 has-text-primary'>
    All order management steps from processing customer inquiries and quotes to routing shipments and selecting carriers are taken care of by AWI. In addition, warehouse management from receiving and picking the product to loading and shipping are handled in-house.
    </div>
    `,
    right: `
    <div class='has-text-weight-light'>
    Other services like managing and delivering business rules, performance, information, finished product inventories, capital assets, transportation, product life cycle, import / export requirements and supply chain delivery risk are all catered to by AWI.<br/><br/>Based on this model, we provide deliver service to Amway Corporation, Aventis, Pfizer Inc and Johnson & Johnson from our warehouses across north and west India.
    </div>
    `,
  },
]

export const Services = () => {
  return (
    <Layout>
      <Headline
        title="Alliance World Services"
        excerpt="The Alliance World group companies have a broad portfolio containing FMCG manufacturing, pharmaceuticals, agriculture & engineering tech."
      />

      <section className="section">
        <div className="container">
          <h3
            className="is-size-3"
            data-sal="slide-right"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            Services
          </h3>
          <br />
          <h5
            style={{ width: "80%" }}
            data-sal="slide-right"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="ease"
            className="is-size-4 has-text-weight-light"
          >
            The Alliance World group companies have a broad portfolio containing
            FMCG manufacturing, pharmaceuticals, agriculture &
            engineering tech.
          </h5>
          <br />
        </div>
      </section>

      <section className="section">
        <div className="container">
          {servicesList.map((currentItem, i) => {
            return (
              <div
                key={currentItem.name}
                data-sal="slide-right"
                data-sal-duration="800"
                data-sal-delay={300 + i * 100}
                data-sal-easing="ease"
                className={css(styles.accordion) + " is-relative accordion"}
              >
                <div className={css(styles.summary) + " summary"}>
                  <h6
                    className={
                      css(styles.title) + " is-size-4  has-text-primary"
                    }
                  >
                    {currentItem.name}
                  </h6>
                  <h6
                    className={
                      css(styles.desc) + " has-text-weight-light is-size-5"
                    }
                  >
                    {currentItem.desc}
                  </h6>
                  <span className={css(styles.icon)}>
                    <span className={css(styles.icon_design)}>+</span>
                  </span>
                </div>
                <input
                  type="checkbox"
                  className={css(styles.check) + " check"}
                />
                <div className={css(styles.details) + " details"}>
                  {currentItem.imp1 && currentItem.imp2 ? (
                    <div className="has-text-weight-light is-size-4 has-text-primary">
                      <br />
                      <br />
                      {currentItem.imp1}
                      <br />
                      <br />
                      {currentItem.imp2}
                    </div>
                  ) : null}
                  <br />
                  <br />
                  <GridTwo
                    left={currentItem.left}
                    right={currentItem.right}
                  ></GridTwo>
                  <br />
                  <br />
                </div>
              </div>
            )
          })}
        </div>
      </section>

      <section className="section" id="certification">
        <div className="container">
          <h3 className="is-size-3">Certifications</h3>
          <br />
          <img
            src={"/cert/qc1.png"}
            style={{
              height: "120px",
              padding: "0 1rem",
              objectFit: "contain",
              margin: "1rem auto",
            }}
          ></img>
          <img
            src={"/cert/qc2.png"}
            style={{
              height: "120px",
              padding: "0 1rem",
              objectFit: "contain",
              margin: "1rem auto",
            }}
          ></img>
          <img
            src={"/cert/qc3.png"}
            style={{
              height: "120px",
              padding: "0 1rem",
              objectFit: "contain",
              margin: "1rem auto",
            }}
          ></img>
          <img
            src={"/cert/qc4.png"}
            style={{
              height: "120px",
              padding: "0 1rem",
              objectFit: "contain",
              margin: "1rem auto",
            }}
          ></img>
        </div>
      </section>
    </Layout>
  )
}

const styles = StyleSheet.create({
  check: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    opacity: 0,
    cursor: "pointer",
  },
  accordion: {
    borderBottom: "1px solid #ebebeb",
  },
  summary: {
    display: "flex",
    padding: "1rem",
    flexWrap: "wrap",
    alignItems: "center",
  },
  title: {
    width: "40%",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  desc: {
    width: "40%",
    "@media (max-width: 768px)": {
      width: "80%",
    },
  },
  icon: {
    width: "20%",
  },
  icon_design: {
    width: "32px",
    height: "32px",
    fontSize: "42px",
    fontWeight: "300",
    borderRadius: "50%",
    display: "flex",
    float: "right",
    color: "#aaa",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #aaa",
  },
  headline: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    "@media (max-width: 768px)": {
      gridTemplateColumns: "1fr",
    },
  },
})

export default Services
